import * as React from 'react'
import { domToReact } from 'html-react-parser'
import loadable from '@loadable/component'
import { replaceLink } from '/src/functions/parse/gatsbyLink'

const UnorderedList = loadable(() =>
    import('/src/components/Lists/UnorderedList')
)
const OrderedList = loadable(() => import('/src/components/Lists/OrderedList'))

export const parseStandardBullets = () => {
    const spec = {
        replace: (domNode) => {
            if (domNode.name === 'ul') {
                return (
                    <UnorderedList>
                        {domToReact(domNode.children)}
                    </UnorderedList>
                )
            } else if (domNode.name === 'ol') {
                return <OrderedList>{domToReact(domNode.children)}</OrderedList>
            }

            replaceLink(domNode)
        }
    }

    return spec
}
