import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import useCheckJsStatus from '/src/hooks/useCheckJsStatus'
import parse from 'html-react-parser'
import { parseStandardBullets } from '/src/functions/parse/standardBullets'
import joinClasses from '/src/functions/joinClasses'
import './styles.scss'

const AccordionItem = ({ data }) => {
    const browserHasJs = useCheckJsStatus()

    const [isExpanded, setToggleExpansion] = useState(false)
    const [setHeight, setHeightState] = useState('0px')

    const content = useRef(null)

    const toggleAccordionItem = () => {
        setToggleExpansion((prev) => !prev)
        setHeightState(
            isExpanded === true ? '0px' : `${content.current.scrollHeight}px`
        )
    }

    const classes = [
        'c-accordion-item',
        ...(browserHasJs ? (isExpanded ? ['is-open'] : ['is-closed']) : [])
    ]

    const concatenatedClasses = joinClasses(classes)

    return (
        <div className={concatenatedClasses}>
            <div
                role="button"
                tabIndex="0"
                className="c-accordion-item__title"
                onClick={toggleAccordionItem}
                onKeyDown={toggleAccordionItem}
            >
                <span>{data.title}</span>
            </div>
            <div
                className="c-accordion-item__content"
                ref={content}
                style={browserHasJs ? { maxHeight: `${setHeight}` } : {}}
            >
                <div className="c-accordion-item__content-inner">
                    {parse(data.content, parseStandardBullets())}
                </div>
            </div>
        </div>
    )
}

AccordionItem.propTypes = {
    /**
     * Data
     */
    data: PropTypes.shape({
        title: PropTypes.string.isRequired,
        content: PropTypes.string.isRequired
    }).isRequired
}

AccordionItem.defaultProps = {
    data: {
        title: 'Accordion item title',
        content: 'Accordion item content'
    }
}

export default AccordionItem
